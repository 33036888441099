.footer{
    background: #355279;
    height: 40px;
    font-size: 20px;
    color: #fff;
    text-align: center;
    margin-top: 2em;
}

.mail{
  color: rgb(131 184 255)
}