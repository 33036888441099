.formRegister{
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    padding-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border-radius: 0.8rem;
}

.Address{
    display: flex;
    justify-content: space-around;
}

.inputBox{
    border: 1px solid cadetblue;
    border-radius: 12rem;
    display: flex;
    padding: 2px 4px;
    justify-content: space-around;
    width:fit-content;
    margin-bottom: 10px;
    width: 90%;
}

.optionBox{
    border: 1px solid cadetblue;
    display: flex;
    padding: 2px 6px;
    margin-bottom: 10px;
    width: 90%;
    border-radius: 12rem;
}

.optionBox select{
    margin-right: 3px;
}

.inputBox input{
    border: none;
    width: 98%
}


.textareaBox {
    border: 1px solid cadetblue;
    border-radius: 1rem;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 5px;
}

.textareaBox textarea{
    border: none;
    resize: none;
}
.textareaBox textarea:focus{
    outline: none;
}

.inputBox input:focus{
    outline: none;
}

.submit {
    margin-top: 1rem;
    font-family: Assistant-Bold, Assistant;
    margin-bottom: 15px;
    font-size: 26px;
    border-radius: 50px;
    border: none;
    background: rgb(110, 171, 246);
    color: rgb(255, 255, 255);
    padding: 0.5rem 2rem;
    cursor: pointer;
}

.projectiD::-webkit-outer-spin-button,.projectiD::-webkit-inner-spin-button{
    -webkit-appearance: "none"
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}