.page{ font-Family: Assistant-Bold, Assistant;
    width: 70%;
    margin: 0px auto;
    color: #355279;
    font-size:30px;
}


.thnx1{
background-Image: url(../SVG/thanx.png);
height: 6em;
background-Repeat: no-repeat;
background-Position: center;
background-Size: contain; 
}

.thnx2{
    margin-top: 2em;
    background-Image: url("../SVG/thanx2.png");
    height: 25em;
    background-Repeat: no-repeat;
    background-Position: center;
    background-Size: contain;
}

.about{
    margin-top: 2em;
    text-align: center;
    background-color: rgb(213 213 213 / 35%);
    padding: 2em 3em;
}

.SFIimg{
    background-Image: url("../SVG/SFI.png");
    height: 11em;
    background-Repeat: no-repeat;
    background-Position: center;
    background-Size: contain;
}

.hagarimg{
    background-Image: url("../SVG/hagar.png");
    height: 8em;
    background-Repeat: no-repeat;
    background-Position: center;
    background-Size: contain;
}