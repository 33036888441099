.mainBody {
    width: 70%;
    margin: 0px auto;
}

.firstArticle {
    background-color: rgba(235, 238, 242, 0.35);
    margin: 0px auto;
    height: 350px;
    font-size: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.firstArticle p {
    color: rgb(53, 82, 121);
    text-align: center;
}

.article {
    margin: 30px auto 10px;
    font-size: 26px;
    color: rgb(53, 82, 121);
    text-align: center;
}

.howTo {
    margin: 10px auto;
    height: 200px;
    font-size: 26px;
    color: rgb(53, 82, 121);
    text-align: center;
}

.howTo hr,
.article hr,
.reasons hr:first-of-type {
    border: none;
    width: 100px;
    height: 3px;
    background: rgb(110, 171, 246);
}

.navigate {
    display: flex;
    margin-top: 3em;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.navigate button {
    font-family: Assistant-Bold, Assistant;
    cursor: pointer;
    font-size: 26px;
    border-radius: 50px;
}

.navigate button:first-child {
    margin-bottom: 15px;
    border: none;
    background: rgb(110, 171, 246);
    color: rgb(255, 255, 255);
    padding: 20px 58px;
}

.navigate button:last-child {
    margin-bottom: 15px;
    color: rgb(53, 82, 121);
    border: 1.5px solid rgb(110, 171, 246);
    background: rgb(255, 255, 255);
    padding: 20px 30px;
}

.reasons {
    width: 60%;
    margin: 30px auto 10px;
    font-size: 26px;
    color: rgb(53, 82, 121);
}

.reasons h2 {
    align-items: center;
}

.reasons>div {
    display: flex;
}

.reasons>div>div {
    margin-right: 0.7rem;
}

.reasons h3 {
    margin: 0.2em 0em;
}