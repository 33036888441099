.categoriesLine{
    display: flex;
    justify-content: space-around;
    width: 80%;
    margin: 0px auto 0px;
    cursor: pointer;
}

.formQuestion{
    background-color: #f9f9f9;
    width: 80%;
    margin: 20px auto;
    border-radius: 0.8rem;
    font-size: 20px;
    color: #355279;
    padding: 1px;
}
